import { LoadingButton } from "@mui/lab";
import { Box, Container, CssBaseline, Grid, Typography } from "@mui/material";
import FsLightbox from "fslightbox-react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import agent from "../../app/api/agent";
import LoadingComponent from "../../app/layout/LoadingComponent";
import { Track } from "../../app/models/Tracks";
import { useAppSelector } from "../../app/store/configureStore";


export default function TrackDetais() {

    const [loading, setLoading] = useState(true);
    const [isApproving, setIsApproving] = useState(true);
    const [track, setTrack] = useState<Track>();
    const { id } = useParams<{ id: string }>();
    const { user } = useAppSelector(state => state.account);
    const [lightboxController, setLightboxController] = useState({
        toggler: false,
        slide: 1
    });

    function openLightboxOnSlide(number: number) {
        setLightboxController({
            toggler: !lightboxController.toggler,
            slide: number
        });
    }

    useEffect(() => {
        agent.Tracks.details(id ?? "-1")
            .then(track => {
                console.log(track);
                setTrack(track);
                setIsApproving(false);
            })
            .catch(error => {
                toast.error(error);
                console.error(error);
            })
            .finally(() => setLoading(false));
    }, [id]);

    function handleApprove() {
        setIsApproving(true);
        agent.Tracks.approve(id)
            .then(track => {
                setTrack(track);
                //console.log(track);
            })
            .catch(error => {
                toast.error(error);
                console.error(error);
            })
            .finally(() => setIsApproving(false));
    }

    if (loading) return <LoadingComponent message='Загружаем проходку...' />

    if (!track) return (
        <>
            <CssBaseline />
            <Box sx={{
                mt: 8,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
            >
                <Typography variant="h5" marginBottom={3}>Информация о проходке #{id} не найдена!</Typography>
            </Box>
        </>
    )

    const formatter = new Intl.DateTimeFormat("ru-Ru", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
    })

    return (
        <>
            <CssBaseline />
            <Box sx={{
                mt: 8,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
            >
                <Typography variant="h5" marginBottom={3}>Проходка #{track.id}</Typography>
                <Container component="main" maxWidth="xs">
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            Дата: {formatter.format(new Date(track.date))}
                        </Grid>
                        <Grid item xs={12}>
                            {track.walker.role}: {track.walker.name}
                        </Grid>
                        <Grid item xs={12}>
                            Потрачено Энергий: {track.energySpent} <br /> Заработано GST: {track.earnedGST}<br /> Починка: {track.repairCost}<br />
                        </Grid>

                        <Grid item xs={6}>
                            <label>GST до: {track === undefined ? "?" : Math.round((track.balanceAfter - track.earnedGST + track.repairCost) * 100) / 100}</label>
                        </Grid>
                        <Grid item xs={6}>
                            <label>GST после: {track?.balanceAfter}</label>
                        </Grid>
                        {track.comment ? (
                            <Grid item xs={12}>
                                Комментарий: {track.comment}
                            </Grid>
                        ) : null}

                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                {track.images?.map((image, index) => (
                                    <Grid item key={index} xs={4}>
                                        <img src={image.path} alt="screen" width="100%" onClick={() => openLightboxOnSlide(index + 1)} />
                                    </Grid>
                                ))}
                            </Grid>
                            <FsLightbox
                                toggler={lightboxController.toggler}
                                sources={track.images?.map((image) => image.path)}
                            />

                        </Grid>

                        {"admin teamleader superviser".includes(user?.role.toLowerCase() ?? "oooops") ?
                            <Grid item xs={12}>
                                <LoadingButton
                                    loading={isApproving}
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    sx={{ mt: 3, mb: 2 }}
                                    onClick={handleApprove}
                                >
                                    Подтвердить
                                </LoadingButton>
                            </Grid>
                            : null
                        }
                    </Grid>
                </Container>
            </Box>
        </>
    )
}