import { AccountCircle } from "@mui/icons-material";
import MenuIcon from '@mui/icons-material/Menu';
import { Menu, Fade, MenuItem, Link, IconButton } from "@mui/material";
import React from "react";
import { signOut } from "../../features/account/accountSlice";
import { useAppDispatch, useAppSelector } from "../store/configureStore";

export default function SignedInMenu() {
    const dispatch = useAppDispatch();
    const { user } = useAppSelector(state => state.account);
    const [anchorEl, setAnchorEl] = React.useState(null);

    const open = Boolean(anchorEl);
    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>

            <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{ mr: 2 }}
                onClick={handleClick}
            >
                <MenuIcon />
            </IconButton>

            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                TransitionComponent={Fade}
            >
                <MenuItem onClick={handleClose} key={'email'}>
                    <AccountCircle />&nbsp;{user?.email}
                </MenuItem>
                <MenuItem onClick={handleClose} key={'Role'}>Роль: {user?.role}</MenuItem>
                <MenuItem onClick={handleClose} key={'account'}>
                    <Link href={"/"} underline="none">Личный кабинет</Link>
                </MenuItem>

                {"admin teamleader superviser".includes(user?.role.toLowerCase() ?? "oooops") ?
                    <MenuItem onClick={handleClose} key={'register'}>
                        <Link href={"/account/register"} underline="none">Создать пользователя</Link>
                    </MenuItem> : null
                }
                {"admin teamleader superviser".includes(user?.role.toLowerCase() ?? "oooops") ?
                    <MenuItem onClick={handleClose} key={'statistics'}>
                        <Link href={"/team/statistics"} underline="none">Статистика по ячейке</Link>
                    </MenuItem> : null
                }


                <MenuItem onClick={() => { dispatch(signOut()); }} key={'exit'}>Выход</MenuItem>
            </Menu>
        </>
    );
}