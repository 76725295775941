import { AppBar, Box, CardMedia, IconButton, Switch, Toolbar } from "@mui/material";
import { useAppSelector } from "../store/configureStore";
import SignedInMenu from "./SignedInMenu";

interface Props {
    darkMode: boolean;
    handleThemeChange: () => void;
}

export default function Header({ darkMode, handleThemeChange }: Props) {

    const { user } = useAppSelector(state => state.account);

    return (
        <AppBar position="static">
            <Toolbar sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', backgroundColor: '#141d37' }}>

                <Box display='flex' alignItems='center'>
                    <IconButton href="/" sx={{ p: 0 }}>
                        <CardMedia
                            component="img"
                            image="/BestWay-logo-small.png"
                        />
                    </IconButton>
                    <Switch checked={darkMode} onClick={handleThemeChange} sx={{ ml: 2 }} />
                </Box>


                <Box display='flex' alignItems='center'>
                    {user ? <SignedInMenu /> : null}
                </Box>

            </Toolbar>
        </AppBar >
    )
}