import { LinearProgress, List, ListItem, ListItemButton, ListItemIcon, ListItemText, ListSubheader } from "@mui/material";
import { toast } from "react-toastify";
import agent from "../../app/api/agent";
import { Operation } from "../../app/models/Operations";
import { AttachMoneyOutlined, AutoFixHighOutlined, DirectionsWalkOutlined, HelpOutlineOutlined, OutboxOutlined, StartOutlined, UploadOutlined } from "@mui/icons-material";
import { useEffect, useState } from "react";
import utils from "../../app/api/utils";
import ModalDialog from "../modal/ModalDialog";
import TrackDetails from "../modal/TrackDetails";
import ChestDetails from "../modal/ChestDetails";
import { TrackItem } from "../../app/models/Tracks";
import { Chest } from "../../app/models/Chests";

export default function OperationList() {
    const [loading, setLoading] = useState(true);
    const [operations, setOperations] = useState<Operation[]>([]);
    const [openTrack, setOpenTrack] = useState(false);
    const [openChest, setOpenChest] = useState(false);
    const [modalTrack, setModalTrack] = useState<TrackItem>();
    const [modalChest, setModalChest] = useState<Chest>();

    function handleTrackModal() {
        setOpenTrack(!openTrack);
    }
    function handleChestModal() {
        setOpenChest(!openChest);
    }


    function navigateOperation(operation: Operation) {

        if (!operation.reference) return;

        switch (operation.type) {
            case "Track":
                setModalTrack(operation.track);
                setOpenTrack(true);
                break;
            case "ChestOpening":
                setModalChest(operation.chest);
                setOpenChest(true);
                break;
            case "Correction":
                return;
            case "Encashment":
                return;
            case "InitBalance":
                return;
            case "LevelUp":
                return;
        }
    }

    function operationIcon(code: string) {
        switch (code) {
            case "Track":
                return <DirectionsWalkOutlined />;
            case "ChestOpening":
                return <OutboxOutlined />;
            case "Correction":
                return <AutoFixHighOutlined />;
            case "Encashment":
                return <AttachMoneyOutlined />;
            case "InitBalance":
                return <StartOutlined />;
            case "LevelUp":
                return <UploadOutlined />;
        }
        return <HelpOutlineOutlined />;
    }

    useEffect(() => {
        agent.Operation.list(10)
            .then((data) => {
                setOperations(data);
                //console.log("LastOperationList", data);
            }).catch((error) => {
                toast("Ошибка при загрузке списка последних операций" + error.title);
                console.error("Ошибка при загрузке списка последних операций", error);
            })
            .finally(() => setLoading(false));
    }, []);

    if (loading) {
        return (
            <>
                <LinearProgress />
                "Загрузка списка операций..."
            </>
        );
    }

    return (
        <>
            <List
                sx={{ width: '100%', bgcolor: 'background.paper', mb: 4 }}
                aria-label="Список последних операций"
                subheader={
                    <ListSubheader component="div" id="nested-list-subheader">
                        Последние операции:
                    </ListSubheader>
                }
            >
                {operations.map(item =>
                    <ListItem disablePadding key={item.id}>
                        <ListItemButton onClick={() => navigateOperation(item)} >
                            <ListItemIcon>
                                {operationIcon(item.type)}
                            </ListItemIcon>
                            <ListItemText primary={utils.dateToShortStr(item.date)} secondary={item.typeName} />
                            <ListItemText primary={item.value} secondary={`Баланс: ${item.balance}`} sx={{ textAlign: "right" }} />
                        </ListItemButton>
                    </ListItem>
                )}
            </List>
            <ModalDialog
                handleButtonModal={handleTrackModal}
                open={openTrack}
                title={`Проходка #${modalTrack?.id}`}
                content={<TrackDetails track={modalTrack} />}
            />
            <ModalDialog
                handleButtonModal={handleChestModal}
                open={openChest}
                title={`Сундук #${modalChest?.id}`}
                content={<ChestDetails chest={modalChest} />}
            />

        </>
    )
}