
const formatterFull = new Intl.DateTimeFormat("ru-Ru", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
})

const formatterShort = new Intl.DateTimeFormat("ru-Ru", {
    month: "2-digit",
    day: "2-digit",
})


const utils = {
    round2d: (val: number) => (Math.round(val * 100) / 100),
    dateToStr: (date: Date) => (formatterFull.format(new Date(date))),
    dateToShortStr: (date: Date) => (formatterShort.format(new Date(date))),
}

export default utils;
