import { AccessibilityNew, ChevronRight, ExpandMore } from '@mui/icons-material';
import { LoadingButton, TreeItem, TreeView } from '@mui/lab';
import { Box, CircularProgress, Container, CssBaseline, FormControl, Grid, InputLabel, LinearProgress, MenuItem, Paper, Select, SelectChangeEvent, TextField, Typography } from "@mui/material";
import { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { toast } from 'react-toastify';
import agent from '../../app/api/agent';
import { Leader, Walker } from '../../app/models/User';

export default function Register() {

    const [parentId, setParentId] = useState('');
    const [leaders, setLeaders] = useState<Leader[]>([]);
    const [walker, setWalker] = useState<Walker>();
    const [createdWalker, setCreatedWalker] = useState<Walker>();
    const [loadingWalkers, setLoadingWalkers] = useState(true);

    function uploadWalker() {
        setLoadingWalkers(true);
        agent.Account.getWalkers()
            .then((data) => setWalker(data))
            .catch(error => {
                toast(error);
                console.log(error);
            })
            .finally(() => setLoadingWalkers(false));
    }

    useEffect(() => {
        agent.Account.getLeaders()
            .then(data => {
                setLeaders(data);
                if (leaders.length > 0) setParentId(leaders[0].userId);
                console.log("Leaders: ", data);
            })
            .catch(error => {
                toast(error);
                console.log(error);
            });

        uploadWalker();

    }, [createdWalker]);

    const selectHandleChange = (event: SelectChangeEvent) => {
        setParentId(event.target.value as string);
    };

    const { register, handleSubmit, formState: { isSubmitting, errors, isValid } } = useForm({
        mode: 'all'
    })

    function submitForm(data: any) {
        agent.Account.register(data)
            .then((data) => {
                setCreatedWalker(data);
                toast.info("Пользователь создан!");
                uploadWalker();
            })
            .catch(error => toast(error));


    }

    const renderTree = (nodes: Walker) => (
        <TreeItem key={nodes.userId} nodeId={nodes.userId} label={nodes.name} >
            {Array.isArray(nodes.walkers)
                ? nodes.walkers.map((node) => renderTree(node))
                : null}
        </TreeItem>
    );

    return (
        <Container maxWidth={false} sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={3}>
                {/* Register form */}
                <Grid item xs={12} md={5} lg={3}>
                    <Paper
                        sx={{
                            p: 2,
                            display: 'flex',
                            flexDirection: 'column',
                            minHeight: 240,
                        }}
                    >
                        <Typography component="h1" variant="h5">Создание пользователя</Typography>
                        <Box component="form" onSubmit={handleSubmit(submitForm)} noValidate sx={{ mt: 4 }}>

                            <FormControl fullWidth>
                                <InputLabel id="parentId-label">Для</InputLabel>
                                <Select required labelId="parentId-label" label="Для" value={parentId}
                                    {...register('parentId', {
                                        required: 'Не выбран Тимлидер или Супервайзер для этого пользователя'
                                    })}
                                    onChange={selectHandleChange}
                                >
                                    {leaders?.map(item =>
                                        <MenuItem key={item.userId} value={item.userId}>{item.role === "TeamLeader" ? " - " : null} {item.role}: {item.name}</MenuItem>
                                    )}
                                </Select>
                            </FormControl>

                            <TextField margin="normal" required fullWidth label="Email" autoComplete="username" autoFocus sx={{ mt: 5 }}
                                {...register('email', {
                                    required: 'Поле Email обязательно!'
                                })}
                                error={!!errors.username}
                                helperText={errors?.username?.message}
                            />
                            <TextField margin="normal" required fullWidth label="Пароль" type="password" autoComplete="current-password"
                                {...register('password', {
                                    required: 'Введите пароль!'
                                })}
                                error={!!errors.password}
                                helperText={errors?.password?.message}
                            />
                            <TextField margin="normal" fullWidth label="Имя"
                                {...register('firstName')}
                            />
                            <TextField margin="normal" fullWidth label="Фамилия"
                                {...register('lastName')}
                            />
                            <TextField margin="normal" fullWidth label="Позиция в ячеке" type="number" defaultValue={1}
                                {...register('sortOrder', {
                                    required: 'Не указан номер.',
                                    min: { value: 1, message: 'Порядковый номер должен быть положительным!' },
                                })}
                            />

                            <LoadingButton loading={isSubmitting} type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }} disabled={!isValid}>
                                Добавить
                            </LoadingButton>
                        </Box>
                    </Paper>
                </Grid>
                {/* UsersTable */}
                <Grid item xs={12} md={7} lg={9}>
                    <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', height: '100%', minHeight: 500 }}>

                        <Typography component="h1" variant="h5">Текущие пользователи</Typography>

                        <TreeView
                            defaultExpandIcon={<ChevronRight />}
                            defaultCollapseIcon={<ExpandMore />}
                            defaultExpanded={['root']}
                            sx={{ flexGrow: 1, overflowY: 'auto', mt: 4 }}
                        >
                            {!walker || loadingWalkers ? <LinearProgress sx={{ mt: 2 }} /> : renderTree(walker)}
                        </TreeView>
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    )
}