import { LoadingButton } from "@mui/lab";
import { Box, Container, CssBaseline, Grid, Typography } from "@mui/material";
import FsLightbox from "fslightbox-react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import agent from "../../app/api/agent";
import LoadingComponent from "../../app/layout/LoadingComponent";
import { Chest } from "../../app/models/Chests";
import { useAppSelector } from "../../app/store/configureStore";

export default function ChestDetails() {
    const { id } = useParams<{ id: string }>();
    const { user } = useAppSelector(state => state.account);
    const [loading, setLoading] = useState(true);
    const [chest, setChest] = useState<Chest>();
    const [lightboxController, setLightboxController] = useState({
        toggler: false,
        slide: 1
    });

    function openLightboxOnSlide(number: number) {
        setLightboxController({
            toggler: !lightboxController.toggler,
            slide: number
        });
    }


    useEffect(() => {
        agent.Operation.chestDetails(id ?? "-1")
            .then(chest => {
                console.log(chest);
                setChest(chest);
            })
            .catch(error => {
                toast.error("Ошибка при загрузке данных: " + error);
                console.error("Load chest details error: ", error);
            })
            .finally(() => setLoading(false));
    }, [id]);

    console.log("chest",chest);

    if (loading) return <LoadingComponent message='Загрузка данных...' />

    if (!chest) return (
        <>
            <CssBaseline />
            <Box sx={{
                mt: 8,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
            >
                <Typography variant="h5" marginBottom={3}>Информации о вскрытии сундука #{id} не найдена!</Typography>
            </Box>
        </>
    )

    const formatter = new Intl.DateTimeFormat("ru-Ru", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
    })

    return (
        <>
            <CssBaseline />
            <Box sx={{
                mt: 8,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
            >
                <Typography variant="h5" marginBottom={3}>Сундук #{chest.id}</Typography>
                <Container component="main" maxWidth="xs">
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            Дата: {formatter.format(new Date(chest.date))}
                        </Grid>
                        <Grid item xs={12}>
                            Walker: {chest.firstName} {chest.lastName}
                        </Grid>
                        <Grid item xs={12}>
                            Потрачено GST: {chest.spentGST} <br />
                            Выпало из сундука: <br />
                            {chest.receivedGST > 0 ? `GST: ${chest.receivedGST}<br/>` : null}
                            {chest.comment}
                        </Grid>

                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                {chest.images?.map((image, index) => (
                                    <Grid item key={index} xs={4}>
                                        <img src={image} alt="screen" width="100%" onClick={() => openLightboxOnSlide(index + 1)} />
                                    </Grid>
                                ))}
                            </Grid>
                            <FsLightbox
                                toggler={lightboxController.toggler}
                                sources={chest.images?.map((image) => image)}
                            />

                        </Grid>

                        {/* {"admin teamleader superviser".includes(user?.role.toLowerCase() ?? "oooops") ?
                            <Grid item xs={12}>
                                <LoadingButton
                                    loading={isApproving}
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    sx={{ mt: 3, mb: 2 }}
                                    onClick={handleApprove}
                                >
                                    Подтвердить
                                </LoadingButton>
                            </Grid>
                            : null
                        } */}
                    </Grid>
                </Container>
            </Box>
        </>
    )
}