import { Grid } from "@mui/material";
import FsLightbox from "fslightbox-react";
import { useState } from "react";
import utils from "../../app/api/utils";
import { Chest } from "../../app/models/Chests";
import { TrackItem } from "../../app/models/Tracks";

interface Props {
    track?: TrackItem;
}

export default function TrackDetails({ track }: Props) {

    const [lightboxController, setLightboxController] = useState({
        toggler: false,
        slide: 1
    });

    function openLightboxOnSlide(number: number) {
        setLightboxController({
            toggler: !lightboxController.toggler,
            slide: number
        });
    }


    if (!track) return <Grid container spacing={2}>Не задана проходка для отображения!</Grid>

    return (
        <Grid container spacing={2} flexDirection={"column"}>
            <Grid item>
                Walker: <strong>{track.firstName} {track.lastName}</strong>
            </Grid>
            <Grid item>
                Дата: <strong>{utils.dateToStr(track.date)}</strong>
            </Grid>
            <Grid item>
                Потрачено энергий: {track.energySpent} <br />
                Заработано GST: {track.earnedGST}<br />
                Стоимость починки: {track.repairCost}
            </Grid>
            {track.comment ?
                <Grid item>
                    Комментарий: <strong>{track.comment}</strong>
                </Grid> : null
            }
            <Grid item container spacing={2} justifyContent={"space-around"}>
                {track.images?.map((image, index) => (
                    <Grid item key={index} minWidth={"100px"} maxWidth={"200px"}>
                        <img width={"100%"} src={image} alt="screen" onClick={() => openLightboxOnSlide(index + 1)} />
                    </Grid>
                ))}
            </Grid>
            <FsLightbox
                toggler={lightboxController.toggler}
                sources={track.images?.map((image) => image)}
            />
        </Grid>
    )
}