import { createTheme, CssBaseline, ThemeProvider } from "@mui/material";
import { Route, Routes } from "react-router-dom";
import Account from "../../features/account/Account";
import TrakAdd from "../../features/operations/TrackAdd";
import Header from "./Heder";
import { useCallback, useEffect, useState } from "react";
import Register from "../../features/account/Register";
import Login from "../../features/account/Login";
import { useAppDispatch, useAppSelector } from "../store/configureStore";
import { fetchCurrentUser } from "../../features/account/accountSlice";
import LoadingComponent from "./LoadingComponent";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import LevelUp from "../../features/operations/LevelUp";
import ChestOpening from "../../features/operations/ChestOpening";
import TrackApprove from "../../features/track/TrackApprove";
import TrackDetais from "../../features/track/TrackDetails";
import ChestDetails from "../../features/operations/ChestDetails";
import OperationDetails from "../../features/operations/OperationDetails";
import TeamStatistics from "../../features/TeamLeader/TeamStatistics";

function App() {
  const { user } = useAppSelector(state => state.account);
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(true);

  const initApp = useCallback(async () => {
    try {
      await dispatch(fetchCurrentUser());
    } catch (error) {
      console.log(error);
    }
  }, [dispatch])

  useEffect(() => {
    initApp().then(() => setLoading(false));
  }, [initApp])

  const [darkMode, setDarkMode] = useState(false);
  const paletteType = darkMode ? 'dark' : 'light'
  const theme = createTheme({
    palette: {
      mode: paletteType,
      background: {
        default: paletteType === 'light' ? '#eaeaea' : '#121212'
      }
    }
  })

  function handleThemeChange() {
    setDarkMode(!darkMode);
  }

  if (loading) return <LoadingComponent message='Инициализация приложения...' />

  return (
    <>
      <ThemeProvider theme={theme}>
        <ToastContainer position="bottom-center" hideProgressBar />
        <CssBaseline />
        <Header darkMode={darkMode} handleThemeChange={handleThemeChange} />
          {!user ? (
            <Routes>
              <Route path="/" element={<Login />} />
            </Routes>
          ) : (
            <Routes>
              <Route path="/" element={<Account />} />
              <Route path="/account" element={<Account />} />
              <Route path="/account/register" element={<Register />} />
              <Route path="/track-add" element={<TrakAdd />} />
              <Route path="/level-up" element={<LevelUp />} />
              <Route path="/chest-opening" element={<ChestOpening />} />
              <Route path="/track-approve/:id" element={<TrackApprove />} />
              <Route path="/track-details/:id" element={<TrackDetais />} />
              <Route path="/chest-details/:id" element={<ChestDetails />} />
              <Route path="/operation-details/:id" element={<OperationDetails />} />
              <Route path="/team/statistics" element={<TeamStatistics />} />              
            </Routes>
          )}

      </ThemeProvider>
    </>
  );
}

export default App;
