import { Dialog, DialogContent, DialogTitle, IconButton, Modal } from "@mui/material";
import { ReactNode } from "react";
import { CancelOutlined } from "@mui/icons-material";

interface ModalDialogProps {
    open: boolean;
    handleButtonModal: () => void;
    title?: string;
    content: ReactNode;
}

export default function ModalDialog({ open, handleButtonModal, title, content }: ModalDialogProps) {

    return (
        <Dialog
            open={open}
            onClose={handleButtonModal}
            scroll={"body"}
            fullWidth={false}
            maxWidth={false}
        >
            <IconButton
                color="primary"
                aria-label="Закрыть окно"
                component="label"
                onClick={handleButtonModal}
                sx={{ float: "right" }}>
                <CancelOutlined sx={{ width: 32, height: 32 }} />
            </IconButton>

            {title ? <DialogTitle id="scroll-dialog-title">{title}</DialogTitle> : null}
            <DialogContent children={content} />

        </Dialog>
    );
}