import { LoadingButton } from "@mui/lab";
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import { Box, Button, CircularProgress, Container, CssBaseline, Grid, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { FieldValues, useForm } from "react-hook-form";
import ImageUploading, { ImageListType } from "react-images-uploading";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import agent from "../../app/api/agent";
import { Delete, Refresh } from "@mui/icons-material";

export default function LevelUp() {

    const { register, watch, handleSubmit, formState: { errors, isValid } } = useForm({
        mode: 'all'
    })

    const watchSpentGST = watch("spentGST", 0);
    const navigate = useNavigate();
    const [balance, setBalance] = useState<number>();
    const [images, setImages] = useState<ImageListType>([]);
    const [submitting, setSubmitting] = useState(false);

    useEffect(() => {
        agent.Operation.balance()
            .then(b => setBalance(b));
    }, [balance]);


    function submitForm(data: FieldValues) {

        setSubmitting(true);

        var imageSetId: number | null = null;

        //Заливаем все изображения на сервер
        const formData = new FormData();
        images.map(image => formData.append("files", image.file!));

        agent.File.uploadImages(formData)
            .then(value => {
                console.log('Images stored. ImageSetId=', value);
                imageSetId = value;

                agent.Operation.levelUp(data.spentGST, data.newLevel, imageSetId)
                    .then(() => {
                        toast.success("Запись о поднятии уровня успешно добавлена.");
                        navigate('/');
                    })
                    .catch(error => {
                        console.error(error);
                        setSubmitting(false);
                        toast(error);
                    });
            })
            .catch(error => {
                console.log(error);
                toast(error);
            });
    }

    const onChangeImageList = (
        imageList: ImageListType,
        addUpdateIndex: number[] | undefined
    ) => {
        setImages(imageList as never[]);
        console.log(addUpdateIndex, imageList);
    };

    return (
        <>

            <CssBaseline />
            <Box component="form" onSubmit={handleSubmit(submitForm)}
                noValidate
                sx={{
                    mt: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Typography variant="h5" marginBottom={3}>Подняте уровня (Level Up)</Typography>
                <Container component="main" maxWidth="xs">
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                required
                                label="Потрачено GST"
                                fullWidth
                                type="number"
                                {...register('spentGST', {
                                    required: 'Укажите стоимость перехода на новый уровень.',
                                    min: { value: 0, message: 'Количество должно быть положительным!' },
                                })}
                                error={!!errors.spentGST}
                                helperText={errors?.spentGST?.message}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                label="Новый уровень"
                                fullWidth
                                type="number"
                                {...register('newLevel', {
                                    required: 'Укажите уровень.',
                                    min: { value: 1, message: 'Уровень должен быть положительным!' },
                                })}
                                error={!!errors.newLevel}
                                helperText={errors?.newLevel?.message}
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <label>GST до: </label>
                            {balance === undefined ? <CircularProgress size={15} /> : balance}
                        </Grid>
                        <Grid item xs={6} textAlign={"right"}>
                            <label>GST после:</label> {watchSpentGST > 0 && balance ? balance - watchSpentGST : "?"}
                        </Grid>
                        <Grid item xs={12}>
                            <ImageUploading
                                multiple
                                value={images}
                                onChange={onChangeImageList}
                                inputProps={{ name: 'imagesInput' }}
                            >
                                {({
                                    imageList,
                                    onImageUpload,
                                    onImageRemoveAll,
                                    onImageUpdate,
                                    onImageRemove,
                                    isDragging,
                                    dragProps
                                }) => (
                                    // write your building UI
                                    <Grid container className="upload__image-wrapper" spacing={1}>
                                        <Grid item xs={12}>
                                            <LoadingButton onClick={onImageUpload} {...dragProps}>
                                                <AddPhotoAlternateIcon fontSize="large" />

                                            </LoadingButton>
                                            <LoadingButton onClick={onImageRemoveAll}>
                                                <Delete fontSize="large" />
                                            </LoadingButton>
                                        </Grid>
                                        {imageList.map((image, index) => (
                                            <Grid item xs={4} key={index} className="image-item">
                                                <img src={image.dataURL} alt="screen" width="100%" />
                                                <div style={{ display: "flex" }}>
                                                    <Button onClick={() => onImageUpdate(index)}><Refresh /></Button>
                                                    <Button onClick={() => onImageRemove(index)}><Delete /></Button>
                                                </div>
                                            </Grid>
                                        ))}
                                    </Grid>
                                )}
                            </ImageUploading>
                        </Grid>





                        <Grid item xs={12}>
                            <LoadingButton
                                loading={submitting}
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                                disabled={!isValid}
                            >
                                Зафиксировать
                            </LoadingButton>
                        </Grid>
                    </Grid>
                </Container>
            </Box>

        </>
    );
}