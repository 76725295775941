import { LoadingButton } from "@mui/lab";
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import { Delete, Refresh } from "@mui/icons-material";
import { Box, Button, CircularProgress, Container, CssBaseline, Grid, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { FieldValues, useForm } from "react-hook-form";
import ImageUploading, { ImageListType } from "react-images-uploading";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import agent from "../../app/api/agent";

export default function ChestOpening() {

    const { register, watch, handleSubmit, formState: { errors, isValid } } = useForm({
        mode: 'all'
    })

    const watchSpentGST: number = watch("spentGST", 0);
    const newBalance: number = watch("receivedGST", 0) - watch("spentGST", 0);
    const [images, setImages] = useState<ImageListType>([]);
    const [submitting, setSubmitting] = useState(false);

    const navigate = useNavigate();
    const [balance, setBalance] = useState<number>();

    useEffect(() => {
        agent.Operation.balance()
            .then(b => setBalance(b));
    }, [balance]);


    async function submitForm(data: FieldValues) {
        //console.log(data);

        setSubmitting(true);

        var imageSetId: number | null = null;

        //Заливаем все изображения на сервер
        const formData = new FormData();
        images.map(image => formData.append("files", image.file!));

        agent.File.uploadImages(formData)
            .then(value => {
                console.log('Images stored. ImageSetId=', value);
                imageSetId = value;
                data["imageSetId"] = imageSetId;
                console.log('data["imageSetId"]', data["imageSetId"]);
                agent.Operation.chestOpening(data)
                    .then(() => {
                        toast.success("Запись о вскрытии сундука успешно добавлена.");
                        navigate('/');
                    })
                    .catch(error => {
                        console.error(error);
                        setSubmitting(false);
                        toast(error);
                    });
            })
            .catch(error => {
                console.log(error);
                toast(error);
            });
    }

    const onChangeImageList = (
        imageList: ImageListType,
        addUpdateIndex: number[] | undefined
    ) => {
        setImages(imageList as never[]);
        console.log(addUpdateIndex, imageList);
    };

    return (
        <>

            <CssBaseline />
            <Box component="form" onSubmit={handleSubmit(submitForm)}
                noValidate
                sx={{
                    mt: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Typography variant="h5" marginBottom={3}>Открытие сундука</Typography>
                <Container component="main" maxWidth="xs">
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                required
                                label="Потрачено GST"
                                fullWidth
                                type="number"
                                {...register('spentGST', {
                                    required: 'Укажите стоимость открытия сундука.',
                                    min: { value: 0.01, message: 'Стоимость должна быть не нулевой!' },
                                })}
                                error={!!errors.spentGST}
                                helperText={errors?.spentGST?.message}

                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Получено GST"
                                fullWidth
                                type="number"
                                defaultValue={0}
                                {...register('receivedGST', {
                                    required: 'Не указано количество полученных GST монет.',
                                    min: { value: 0, message: 'Количество должно быть положительным!' },
                                })}
                                error={!!errors.receivedGST}
                                helperText={errors?.receivedGST?.message}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Выпало из сундука"
                                fullWidth
                                {...register('comment')}
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <label>GST до: </label>
                            {balance === undefined ? <CircularProgress size={15} /> : balance}
                        </Grid>
                        <Grid item xs={6} textAlign={"right"}>
                            <label>GST после:</label> {watchSpentGST > 0 && balance ? balance + newBalance : " ? "}
                        </Grid>

                        <Grid item xs={12}>
                            <ImageUploading
                                multiple
                                value={images}
                                onChange={onChangeImageList}
                                inputProps={{ name: 'imagesInput' }}
                            >
                                {({
                                    imageList,
                                    onImageUpload,
                                    onImageRemoveAll,
                                    onImageUpdate,
                                    onImageRemove,
                                    isDragging,
                                    dragProps
                                }) => (
                                    // write your building UI
                                    <Grid container className="upload__image-wrapper" spacing={1}>
                                        <Grid item xs={12}>
                                            <LoadingButton onClick={onImageUpload} {...dragProps}>
                                                <AddPhotoAlternateIcon fontSize="large" />

                                            </LoadingButton>
                                            <LoadingButton onClick={onImageRemoveAll}>
                                                <Delete fontSize="large" />
                                            </LoadingButton>
                                        </Grid>
                                        {imageList.map((image, index) => (
                                            <Grid item xs={4} key={index} className="image-item">
                                                <img src={image.dataURL} alt="screen" width="100%" />
                                                <div style={{ display: "flex" }}>
                                                    <Button onClick={() => onImageUpdate(index)}><Refresh /></Button>
                                                    <Button onClick={() => onImageRemove(index)}><Delete /></Button>
                                                </div>
                                            </Grid>
                                        ))}
                                    </Grid>
                                )}
                            </ImageUploading>
                        </Grid>

                        <Grid item xs={12}>
                            <LoadingButton
                                loading={submitting}
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                                disabled={!isValid}
                            >
                                Зафиксировать
                            </LoadingButton>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </>
    );
}