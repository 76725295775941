import { Grid, LinearProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom"
import { toast } from "react-toastify";
import agent from "../../app/api/agent";
import utils from "../../app/api/utils";
import { Operation } from "../../app/models/Operations";
import TrackDetais from "../track/TrackDetails";
import ChestDetails from "./ChestDetails";

export default function OperationDetails() {
    const { id } = useParams<{ id: string }>();
    const [operation, setOperation] = useState<Operation>();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        agent.Operation.details(id ?? "0")
            .then((data) => {
                setOperation(data);
                console.log("operation:", operation);
            })
            .catch((error) => {
                toast("Ошибка при загрузке данных об операции: " + error);
                console.error("Ошибка при загрузке данных об операции: ", error);
            })
            .finally(() => setLoading(false));

    }, [id]);


    if (!operation) return (<>Не удалось загрузить операцию #{id}</>)

    return (
        <>
            {loading ? <LinearProgress /> : null}
            Операция #{id}<br /><br />
            Тип операции: {operation?.typeName}<br />
            <Grid container spacing={2}>
                <Grid item md={3} xs={6}>Баланс до: {utils.round2d(operation?.balance - operation.value)}</Grid>
                <Grid item>Баланс после: {operation.balance}</Grid>
            </Grid>
            {
                // operation.type === "Track" ? <TrackDetais track={operation.track} /> :
                //     operation.type === "ChestOpening" ? <ChestDetails chest={operation.chest} /> : null
            }

        </>
    )
}