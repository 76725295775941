import axios, { AxiosError, AxiosResponse } from "axios";
import { FieldValues } from "react-hook-form";
import { toast } from "react-toastify";
import { store } from "../store/configureStore";

const sleep = () => new Promise(resolve => setTimeout(resolve, 500));

//axios.defaults.baseURL = 'https://localhost:7158/api/';
axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.withCredentials = true;

const responseBody = (response: AxiosResponse) => response.data;

axios.interceptors.request.use(config => {
    const token = store.getState().account.user?.token;
    if (token) config.headers!.Authorization = `Bearer ${token}`;

    return config;
})

axios.interceptors.response.use(async response => {
    if (process.env.NODE_ENV === 'development') await sleep();
    return response;
}, (error: AxiosError<any, any>) => {
    const { data, status } = error.response!;
    switch (status) {
        case 400:
            if (data.errors) {
                const modelStateErrors: string[] = [];
                for (const key in data.errors) {
                    if (data.errors[key]) {
                        modelStateErrors.push(data.errors[key])
                    }
                }
                toast.error(modelStateErrors.flat().toString());
                console.log('modelStateErrors', modelStateErrors);
                break;
            }
            toast.error(data.title || 'Ошибка:' + error.message);
            console.log('data', data);
            break;
        case 401:
            toast.error(`Пользователь не авторизован! ${data.title} ${data.message}`);
            console.log('401', error);
            break;
        case 500:
            console.log('error: ', error);
            console.log('data: ', data);
            toast.error(data.title || error.message)
            break;
        default:
            console.log('error: ', error);
            console.log('data: ', data);
            toast.error(data.title || error.message)
            break;
    }
    return Promise.reject(error.response);
})


const request = {
    get: (url: string) => axios.get(url).then(responseBody),
    post: (url: string, body: {}) => axios.post(url, body).then(responseBody),
    put: (url: string, body: {}) => axios.put(url, body).then(responseBody),
    delete: (url: string) => axios.delete(url).then(responseBody),
}


const Account = {
    login: (values: any) => request.post('Account/login', values),
    register: (values: any) => request.post('Account/register', values),
    currentUser: () => request.get('Account/currentUser'),
    getLeaders: () => request.get('Account/getLeaders'),
    getWalkers: () => request.get('Account/getWalkers'),
    lastLevelUps: () => request.get('Account/lastLevelUps'),
    lastChest: () => request.get('Account/lastChest'),
}

//todo сделал заглушку для создания по образу 

const Tracks = {
    list: () => request.get('Tracks'),
    details: (id: string) => request.get(`Tracks/${id}`),
    unapproved: () => request.get('Tracks/unapproved'),
    latest: () => request.get('Tracks/latest'),
    approve: (id: string | undefined) => request.get(`Tracks/approve/${id}`),
}

const Operation = {
    balance: () => request.get(`Operation/balance`),
    levelUp: (spentGST: number, newLevel: number, imageSetId: number | null) => request.get(`Operation/levelUp?spentGST=${spentGST}&newLevel=${newLevel}&imageSetId=${imageSetId}`),
    chestOpening: (data: FieldValues) => request.post('Operation/chestOpening', data),
    addTrack: (data: FieldValues) => request.post('Operation/addTrack', data),
    list: (count: number) => request.get(`Operation/list/${count}`),
    chestDetails: (id: string) => request.get(`Operation/chestDetails/${id}`),
    details: (id: string) => request.get(`Operation/details/${id}`),
    walkersOperationList: (dateFrom: string, days: number) => request.get(`Operation/walkersOperationList?dateFrom=${dateFrom}&days=${days}`),
}

const File = {
    upload: (data: FormData) => request.post('File/upload', data),
    uploadImages: (data: FormData) => request.post('File/uploadImages', data),
}

const agent = {
    Account,
    Tracks,
    Operation,
    File,
}

export default agent;