import { LoadingButton } from "@mui/lab";
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import { Delete, Refresh } from "@mui/icons-material";
import { Box, Button, CircularProgress, Container, CssBaseline, Grid, TextField, Typography } from "@mui/material";
import { FieldValues, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import agent from "../../app/api/agent";
import ImageUploading, { ImageListType } from 'react-images-uploading';
import { useEffect, useState } from "react";
import { relative } from "path";

export default function TrakAdd() {

    const { register, watch, handleSubmit, formState: { errors, isValid } } = useForm({
        mode: 'all'
    })

    const navigate = useNavigate();
    const newBalance: number = watch("earnedGST", 0) - watch("repairCost", 0);
    const [images, setImages] = useState<ImageListType>([]);
    const [balance, setBalance] = useState<number>();
    const [submitting, setSubmitting] = useState(false);
    const today = new Date().toISOString().slice(0, 10);

    useEffect(() => {
        agent.Operation.balance()
            .then(b => setBalance(b));
    }, [balance]);


    async function submitForm(data: FieldValues) {

        setSubmitting(true);

        var imageSetId: number | null = null;

        //Заливаем все изображения на сервер
        const formData = new FormData();
        images.map(image => formData.append("files", image.file!));

        agent.File.uploadImages(formData)
            .then(value => {
                console.log('Images stored. ImageSetId=', value);
                imageSetId = value;

                data["imageSetId"] = imageSetId;
                console.log('data["imageSetId"]', data["imageSetId"]);

                agent.Operation.addTrack(data)
                    .then(() => {
                        toast.success("Проходка успешно добавлена. Баланс изменен.");
                        navigate('/');
                    })
                    .catch(error => {
                        console.error(error);
                        toast(error);
                    })
                    .finally(() => setSubmitting(false));
            })
            .catch(error => {
                console.log(error);
                toast(error);
            })
            .finally(() => setSubmitting(false));
    }

    const onChangeImageList = (
        imageList: ImageListType,
        addUpdateIndex: number[] | undefined
    ) => {
        setImages(imageList as never[]);
        console.log(addUpdateIndex, imageList);
    };
    //console.log("newBalance", newBalance);

    return (
        <>

            <CssBaseline />
            <Box component="form" onSubmit={handleSubmit(submitForm)}
                noValidate
                sx={{
                    mt: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Typography variant="h5" marginBottom={3}>Новая проходка</Typography>
                <Container component="main" maxWidth="xs">
                    <Grid container spacing={2}>
                        <Grid item xs={8}>
                            <TextField
                                label="Дата"
                                type="date"
                                defaultValue={today}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                fullWidth
                                {...register('date', {
                                    required: 'Укажите дату проходки.'
                                })}
                                error={!!errors.date}
                                helperText={errors?.date?.message}


                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                required
                                label="Потрачено энергий"
                                fullWidth
                                defaultValue={9}
                                type="number"
                                {...register('energySpent', {
                                    required: 'Не указано количество потраченной энергии.',
                                    min: { value: 0, message: 'Количество должно быть положительным!' },
                                })}
                                error={!!errors.energySpent}
                                helperText={errors?.energySpent?.message}

                            />
                        </Grid>

                        <Grid item xs={12}>
                            <ImageUploading
                                multiple
                                value={images}
                                onChange={onChangeImageList}
                                inputProps={{ name: 'imagesInput' }}
                            >
                                {({
                                    imageList,
                                    onImageUpload,
                                    onImageRemoveAll,
                                    onImageUpdate,
                                    onImageRemove,
                                    isDragging,
                                    dragProps
                                }) => (
                                    // write your building UI
                                    <Grid container className="upload__image-wrapper" spacing={1}>
                                        <Grid item xs={6}>
                                            <Button fullWidth variant="contained" onClick={onImageUpload} {...dragProps}>
                                                <AddPhotoAlternateIcon fontSize="large" />
                                            </Button>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Button fullWidth variant="contained" onClick={onImageRemoveAll}>
                                                <Delete fontSize="large" />
                                            </Button>
                                        </Grid>
                                        {imageList.map((image, index) => (
                                            <Grid item xs={4} key={index} className="image-item" position={"relative"}>
                                                <img src={image.dataURL} alt="screen" width="100%" />
                                                {/* <div style={{ display: "flex", position: "absolute", bottom: 15 }}>
                                                    <Button onClick={() => onImageUpdate(index)}><Refresh /></Button>
                                                    <Button onClick={() => onImageRemove(index)}><Delete /></Button>
                                                </div> */}
                                            </Grid>
                                        ))}
                                    </Grid>
                                )}
                            </ImageUploading>
                        </Grid>
                        <Grid item xs={6} textAlign={"center"} sx={{ mt: 2, mb: 1 }}>
                            <label>GST до: </label>
                            {balance === undefined ? <CircularProgress size={15} /> : balance}
                        </Grid>
                        <Grid item xs={6} textAlign={"center"} sx={{ mt: 2, mb: 1 }}>
                            <label>GST после:</label> {balance ? Math.round((balance + newBalance) * 100) / 100 : " ? "}
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                required
                                label="Проходка"
                                fullWidth
                                type="number"
                                {...register('earnedGST', {
                                    required: 'Не указано количество полученных GST монет.',
                                    min: { value: 0, message: 'Количество должно быть положительным!' },
                                })}
                                error={!!errors.earnedGST}
                                helperText={errors?.earnedGST?.message}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                required
                                label="Починка"
                                type="number"
                                fullWidth
                                {...register('repairCost', {
                                    required: 'Не указана стоимость починки.',
                                    min: { value: 0, message: 'Стоимость должна быть положительной!' },
                                })}
                                error={!!errors.repairCost}
                                helperText={errors?.repairCost?.message}

                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Комментарий"
                                fullWidth
                                type={"text"}
                                {...register('comment')}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <LoadingButton
                                loading={submitting}
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                                disabled={!isValid}
                                size="large"
                            >
                                Добавить
                            </LoadingButton>
                        </Grid>
                    </Grid>
                </Container>
            </Box >
        </>
    );
}