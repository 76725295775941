import { Grid } from "@mui/material";
import FsLightbox from "fslightbox-react";
import { useState } from "react";
import utils from "../../app/api/utils";
import { Chest } from "../../app/models/Chests";

interface Props {
    chest?: Chest;
}

export default function ChestDetails({ chest }: Props) {

    const [lightboxController, setLightboxController] = useState({
        toggler: false,
        slide: 1
    });

    function openLightboxOnSlide(number: number) {
        setLightboxController({
            toggler: !lightboxController.toggler,
            slide: number
        });
    }


    if (!chest) return <Grid container spacing={2}>Не задан сундук для отображения!</Grid>

    return (
        <Grid container spacing={2} flexDirection={"column"}>
            <Grid item>
                Walker: <strong>{chest.firstName} {chest.lastName}</strong>
            </Grid>
            <Grid item>
                Дата: <strong>{utils.dateToStr(chest.date)}</strong>
            </Grid>
            <Grid item>
                GST потрачено: <strong>{chest.spentGST}</strong> <br />
                {chest.receivedGST > 0 ? <>GST получено: <strong>{chest.receivedGST} </strong></> : null}
            </Grid>
            {chest.comment ?
                <Grid item>
                    Выпало из сундука: <strong>{chest.comment}</strong>
                </Grid> : null
            }

            <Grid item container spacing={2} justifyContent={"space-around"}>
                {chest.images?.map((image, index) => (
                    <Grid item key={index} minWidth={"100px"} maxWidth={"200px"}>
                        <img width={"100%"} src={image} alt="screen" onClick={() => openLightboxOnSlide(index + 1)} />
                    </Grid>
                ))}
            </Grid>
            <FsLightbox
                toggler={lightboxController.toggler}
                sources={chest.images?.map((image) => image)}
            />

        </Grid>
    )
}