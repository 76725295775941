import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk';
import OutboxOutlinedIcon from '@mui/icons-material/OutboxOutlined'; import { LoadingButton } from "@mui/lab";
import { Button, Container, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Paper, Select, styled, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@mui/material"
import { useState } from "react";
import RefreshIcon from '@mui/icons-material/Refresh';
import agent from "../../app/api/agent";
import { toast } from "react-toastify";
import { WalkerOperations } from "../../app/models/Operations";
import { TrackItem } from "../../app/models/Tracks";
import { Chest } from "../../app/models/Chests";
import { Walker } from "../../app/models/User";
import utils from '../../app/api/utils';
import ModalDialog from '../modal/ModalDialog';
import ChestDetails from '../modal/ChestDetails';
import TrackDetails from '../modal/TrackDetails';


export default function TeamStatistics() {

    interface DayInfo {
        tracks: TrackItem[];
        chests: Chest[];
        balance: number;
    }

    interface Statistic {
        walker: Walker;
        daysInfo: Record<string, DayInfo>;
    }

    const [loading, setLoading] = useState(false);
    const [stat, setStat] = useState<Statistic[]>();
    const [dateFrom, setDateFrom] = useState(localStorage.getItem('statDateFrom') ?? new Date().toISOString().slice(0, 10));
    const [days, setDays] = useState(parseInt(localStorage.getItem('statDays') ?? "14"));
    const [showChests, setShowChest] = useState(false);
    const [showBalance, setShowBalance] = useState(false);
    const [showRepair, setShowRepair] = useState(false);
    const [openTrack, setOpenTrack] = useState(false);
    const [openChest, setOpenChest] = useState(false);
    const [modalTrack, setModalTrack] = useState<TrackItem>();
    const [modalChest, setModalChest] = useState<Chest>();


    //Берем JSON с балансом, проходками и сундуками, и распихиваем всю информацию по дням.
    //основной ньюанс в том что в операции (баланса) за сегодня может быть указана проходка за вчера или позавчера.
    function updateStatistics(walkerOperations: WalkerOperations[]) {
        let result: Statistic[] = [];

        walkerOperations.forEach((value, key) => {

            let daysInfo: Record<string, DayInfo> = {};

            value.operations.forEach((operation, key) => {
                const index = utils.dateToShortStr((operation.track) ? operation.track.date : operation.date);

                let dayInfo: DayInfo = daysInfo[index] ?? { balance: 0, chests: [], tracks: [] };

                if (operation.track) dayInfo.tracks.push(operation.track);
                if (operation.chest) dayInfo.chests.push(operation.chest);
                dayInfo.balance = operation.balance;

                daysInfo[index] = dayInfo;
            })

            result.push({
                walker: value.walker,
                daysInfo: daysInfo
            });
        });
        //console.log("statistics table:", result);
        setStat(result);
    }

    function handleTrackModal() {
        setOpenTrack(!openTrack);
    }
    function handleChestModal() {
        setOpenChest(!openChest);
    }
    function showTrackModal(track: TrackItem) {
        setModalTrack(track);
        setOpenTrack(true);
    }
    function showChestModal(chest: Chest) {
        setModalChest(chest);
        setOpenChest(true);
    }

    function handleShowChests() {
        setShowChest(!showChests);
    }
    function handleShowBalance() {
        setShowBalance(!showBalance);
    }
    function handleShowRepair() {
        setShowRepair(!showRepair);
    }
    function handleDateChange(event: React.ChangeEvent<HTMLInputElement>) {
        setDateFrom(event.target.value);
        localStorage.setItem('statDateFrom', event.target.value);
        setStat(undefined);
    }
    function handleDayChange(event: React.ChangeEvent<HTMLInputElement>) {
        setDays(parseInt(event.target.value));
        localStorage.setItem('statDays', event.target.value);
        setStat(undefined);
    }

    //Загружаем данные
    function handleUpdate() {
        setLoading(true);
        agent.Operation.walkersOperationList(dateFrom, days)
            .then(data => {
                //console.log("WalkerOperations:", data);
                updateStatistics(data);
            })
            .catch(error => {
                toast.error(`Ошибка при загрузке данных! ${error}`);
            })
            .finally(() => setLoading(false))
    }

    //Выводим информацию за день в ячеке таблицы
    function renderCell(dayInfo?: DayInfo) {

        if (!dayInfo) return (<TableCell sx={{ textAlign: "center" }}> - </TableCell>)

        let trackGST = 0;
        let trackEnergy = 0;
        let trackRepair = 0;
        dayInfo.tracks.forEach(value => {
            trackGST = utils.round2d(trackGST + value.earnedGST);
            trackEnergy = utils.round2d(trackEnergy + value.energySpent);
            trackRepair = utils.round2d(trackRepair + value.repairCost);
        });

        let chestsComment = "";
        dayInfo.chests.forEach(value => {
            chestsComment += chestsComment + " " + value.comment ?? "";
        });

        return (
            <TableCell>
                {showBalance ?
                    <Grid display={'flex'} flexWrap={'nowrap'}>
                        <AttachMoneyIcon color="action" sx={{ width: 20, mt: "-2px" }} />
                        <span>{dayInfo.balance}</span>
                    </Grid> : null
                }

                {dayInfo.tracks.length > 0 ?
                    <Button sx={{ py: "2px" }}
                        onClick={() => showTrackModal(dayInfo.tracks[0])}
                        title={`Починка: ${trackRepair};  Энергий: ${trackEnergy};  Всего проходок: ${dayInfo.tracks.length};`}
                    >
                        <DirectionsWalkIcon color="action" sx={{ width: 20, mt: "-3px" }} />
                        <span>{showRepair ? utils.round2d(trackGST - trackRepair) : trackGST}</span>
                    </Button>
                    : null
                }

                {showChests ?
                    (<Grid display={'flex'} flexWrap={'nowrap'} mt={0.5}>
                        {dayInfo.chests.map(chest =>
                            <Button sx={{ py: "2px" }}
                                onClick={() => showChestModal(chest)}
                                title={chestsComment}
                            >
                                <OutboxOutlinedIcon color="action" sx={{ width: 20, mt: "-3px" }} />
                                <span>{utils.round2d(chest.receivedGST - chest.spentGST)}</span>&nbsp;
                            </Button>
                        )}
                    </Grid>) : null
                }
            </TableCell>
        )

    }

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
            border: 0,
        },
        '& td': {
            verticalAlign: "top",
        },
    }));

    return (
        <Container sx={{ mt: 4, mb: 4 }} maxWidth={false}>
            <Grid item xs={12} component={Paper} sx={{ px: 2, py: 0.1 }}>
                <Grid container spacing={2} sx={{ mt: 1, mb: 4 }}>

                    <Grid item sx={{ mr: "auto" }}>
                        <FormControl fullWidth>
                            <InputLabel id="teamLeadLabel">Статистика ячейки</InputLabel>
                            <Select
                                labelId="teamLeadLabel"
                                label="Статистика ячейки"
                                value={'d6a3a271-ea5b-42f1-b077-27388ac4644d'}
                            >
                                <MenuItem defaultChecked value={'d6a3a271-ea5b-42f1-b077-27388ac4644d'}>TeamLeader: Иванцова Александра</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item>

                        <FormControlLabel
                            control={<Switch color="primary" checked={showBalance} onClick={handleShowBalance} />}
                            label="Показать баланс"
                            labelPlacement="bottom"
                        />
                        <FormControlLabel
                            control={<Switch color="primary" checked={showRepair} onClick={handleShowRepair} />}
                            label="Минус починка"
                            labelPlacement="bottom"
                        />
                        <FormControlLabel
                            control={<Switch color="primary" checked={showChests} onClick={handleShowChests} />}
                            label="Показать сундуки"
                            labelPlacement="bottom"
                        />
                    </Grid>

                    <Grid item>
                        <TextField
                            required
                            label="Дата"
                            type="date"
                            defaultValue={dateFrom}
                            onChange={handleDateChange}
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            required
                            label="Дней"
                            type="number"
                            defaultValue={days}
                            sx={{ maxWidth: 80 }}
                            onChange={handleDayChange}
                        />
                    </Grid>
                    <Grid item>
                        <LoadingButton
                            variant="contained"
                            size="large"
                            loading={loading}
                            sx={{ maxWidth: 80, height: "100%" }}
                            onClick={handleUpdate}
                        >
                            <RefreshIcon />
                        </LoadingButton>
                    </Grid>
                </Grid>
            </Grid>
            <TableContainer component={Paper} sx={{ mt: 4 }}>

                <Table sx={{ minWidth: 650 }} >
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ fontWeight: "bold" }}>Аккаунт / Дата</TableCell>
                            {(() => {
                                let cells = [];
                                let curDate = new Date(dateFrom);
                                for (let i = 1; i <= days; i++) {
                                    cells.push(<TableCell sx={{ textAlign: "center" }}>{utils.dateToShortStr(curDate)}</TableCell>);
                                    curDate.setDate(curDate.getDate() + 1);
                                }
                                return (cells);
                            })()}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {stat ?
                            stat.map((row, key) => (
                                <StyledTableRow
                                    key={row.walker.email}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        {row.walker.name}
                                        <small><br />{row.walker.email}</small>
                                    </TableCell>
                                    {(() => {
                                        let cells = [];
                                        let curDate = new Date(dateFrom);
                                        for (let i = 1; i <= days; i++) {
                                            let index = utils.dateToShortStr(curDate);
                                            cells.push(renderCell(row.daysInfo[index]));
                                            curDate.setDate(curDate.getDate() + 1);
                                        }
                                        return (cells);
                                    })()}

                                </StyledTableRow>
                            )) : (
                                <TableRow>
                                    <TableCell colSpan={days + 1} sx={{ textAlign: "center", p: 5 }}>
                                        Данные не загружены<br />
                                        Выберите дату начала отчета и количество дней, а затем нажмите кнопку &nbsp;
                                        <LoadingButton
                                            variant="contained"
                                            size="large"
                                            loading={loading}
                                            sx={{ maxWidth: 80, height: "100%" }}
                                            onClick={handleUpdate}
                                        >
                                            <RefreshIcon />
                                        </LoadingButton>
                                    </TableCell>
                                </TableRow>
                            )}
                    </TableBody>
                </Table>
            </TableContainer>
            {/* <TrackModal handleButtonModal={handleTrackModal} open={openTrack} track={modalTrack} /> */}
            {/* <ChestModal handleButtonModal={handleChestModal} open={openChest} chest={modalChest} /> */}
            <ModalDialog
                handleButtonModal={handleTrackModal}
                open={openTrack}
                title={`Проходка #${modalTrack?.id}`}
                content={<TrackDetails track={modalTrack} />}
            />
            <ModalDialog
                handleButtonModal={handleChestModal}
                open={openChest}
                title={`Сундук #${modalChest?.id}`}
                content={<ChestDetails chest={modalChest} />}
            />

        </Container>
    )
}