import { Button, CircularProgress, Container, CssBaseline, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import agent from "../../app/api/agent";
// import { Chest } from "../../app/models/Chests";
// import { LevelUp } from "../../app/models/LevelUps";
// import { TrackItem } from "../../app/models/Tracks";
import OperationList from "../operations/OperationList";

export default function Account() {
    const [balance, setBalance] = useState<number>();
    // const [tracks, setTracks] = useState<TrackItem[]>([]);
    // const [chests, setChests] = useState<Chest[]>([]);
    // const [levelUps, setLevelUps] = useState<LevelUp[]>([]);

    // const formatter = new Intl.DateTimeFormat("ru-Ru", {
    //     //year: "numeric",
    //     month: "2-digit",
    //     day: "2-digit",
    // })


    useEffect(() => {
        agent.Operation.balance()
            .then(b => setBalance(b));
        //     agent.Tracks.latest()
        //         .then((items) => {
        //             setTracks(items);
        //             console.log("tracks", items);
        //         })
        //         .catch(error => {
        //             console.error("track recieve error", error);
        //             toast(error);
        //         });
        //     agent.Account.lastChest()
        //         .then((items) => {
        //             setChests(items);
        //             console.log("chests", items);
        //         })
        //         .catch(error => {
        //             console.error("chests recieve error", error);
        //             toast(error);
        //         });
        //     agent.Account.lastLevelUps()
        //         .then((items) => {
        //             setLevelUps(items);
        //             console.log("LevelUps", items);
        //         })
        //         .catch(error => {
        //             console.error("LevelUps recieve error", error);
        //             toast(error);
        //         });
    }, []);

    return (
        <>
            <CssBaseline />
            <Container component="main" maxWidth="xs">
                <Typography variant="h4" mt={6} mb={3} textAlign="center">Личный кабинет</Typography>
                <Grid container spacing={3} >
                    <Grid item xs={12} textAlign={"center"} sx={{ mt: 2, mb: 2 }}>
                        Текущий баланс: <strong> {balance === undefined ? <CircularProgress size={15} /> : balance}</strong>
                    </Grid>

                    <Grid item xs={12}>
                        {/* {tracks.map((item, key) => (
                            <div key={item.id}>
                                #{item.id} <strong>{formatter.format(new Date(item.date))}</strong> - <strong>{item.earnedGST} GST</strong> -  <Link key={key} to={`/track-details/${item.id}`}>{item.statusName}</Link>
                            </div>
                        ))
                        }
                        <br /> */}
                        <Button variant="contained" fullWidth component={Link} to="/track-add">Добавить проходку</Button>
                    </Grid>
                    <Grid item xs={12}>
                        {/* {chests.map((chest, key) => (
                            <div key={key}>
                                Вскрытие сундука #{chest.id}: <strong>{formatter.format(new Date(chest.date))}</strong> - {chest.spentGST} GST
                            </div>
                        ))}
                        <br /> */}
                        <Button variant="contained" fullWidth component={Link} to="/chest-opening">Вскрыть сундук</Button>
                    </Grid>
                    <Grid item xs={12}>
                        {/* {levelUps.map((levelUp, key) => (
                            <div key={key}>
                                Поднятие уровня: <strong>{formatter.format(new Date(levelUp.date))}</strong> - lv {levelUp.newLevel} - {levelUp.spentGST} GST
                            </div>
                        ))} */}
                        <Button variant="contained" fullWidth component={Link} to="/level-up">Поднять уровень</Button>
                    </Grid>
                    <Grid item xs={12}>
                        <OperationList />
                    </Grid>
               </Grid>
            </Container>

        </>
    );
}